<template>
  <div class="couserView">
    <div v-if="yearKc.length > 0 && navIndex == 3" class="year_wrap">
      <div v-for="(item, index) in yearKc" :key="index" class="year_item" :class="currentYear == item.year ? 'on' : ''"
        @click="changeYear(item)">
        {{ item.year }}年
      </div>
    </div>
    <div v-if="smallList.length > 0" class="couserView_title2">
      <span>小班课</span>
      <div class="line" />
      <div>{{ smallList[0].name }}</div>
    </div>
    <!-- <el-collapse v-model="smallChil" class="docList" accordion>
      <el-collapse-item
        v-for="(items, thisindex) in smallList"
        :key="items.id"
        class="item"
        :name="items.id"
      > -->
    <!-- 直播 -->
    <div v-for="(items, index) in smallList" :key="index" class="smallListbox">
      <chaternew :id="items.id" :isxbk="true" class="chater" :if-class="true" :class-id="classesId"
        :cc-config="items ? items.ccConfig : ''" :purchase="IsPurchase" :is-purchase="IsPurchase" :list="items.sections"
        :class-product-line="productLine" :card-code="cardCode" :is-freeze="isFreeze" />
    </div>

    <!-- </el-collapse-item>
    </el-collapse> -->
    <div class="couserView_title">
      <img src="@/assets/img/typeclass/title.png" alt="">
    </div>
    <!-- <el-collapse
      v-model="openIndex"
      class="kcList"
      accordion
      @change="collapseChange"
    > -->
    <!-- <el-collapse-item
        v-for="(item, index) in courseName"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <div class="title">
            <div class="shu" />
            <div class="title-name">
              <i class="icon-a-Frame1 iconfont" />
              <span>{{ item.kcName }}</span>
              <div
                v-if="kind == 1 && item.property == '必修课'"
                class="tag must"
              >
                必修
              </div>
              <div
                v-else-if="kind == 1 && item.property == '选修课'"
                class="tag notRequired"
              >
                选修
              </div>
              <div
                v-else-if="kind == 1 && item.property == '实践课'"
                class="tag practice"
              >
                实践
              </div>
            </div>
          </div>
          <div class="fenge" />
        </template>
</el-collapse-item> -->
    <!-- 按章列表 ---->
    <el-collapse v-model="openChil" class="docList" accordion>
      <el-collapse-item v-for="(thisList, thisindex) in courseList" v-show="thisList.chapterNewVOS.length"
        :key="thisList.id" class="item" :name="thisList.id">
        <template slot="title">
          <div class="docList-title w-b-100">
            <div class="flex-s">
              <div class="couserView_title_line" />
              <div class="title-text ellipsis">
                {{ thisList.title }}
              </div>
              <div :style="thisList.type == 2 ? { color: '#4EA2FF', border: ` 1px solid #4EA2FF` } : {}"
                class="docList-type shrink0">
                {{ liveEnum[thisList.type] }}
              </div>
              <div style="color: #999; font-size: 14px;background:#fff;height: 20px;line-height:20px;padding: 0 8px;"
                class="shrink0">
                共
                {{ thisList.ksTime ? thisList.ksTime : '0' }}
                课时
              </div>
            </div>
            <div style="
                display: flex;
                justify-content: flex-end;
                margin-right: 21px;
                color: #999;
              ">
              <div class="tips shrink0">
                <span v-if="thisList.type == 5" class="ellipsis" style="margin: 0px 0 0 15px; display: block">
                  <el-tooltip class="item" effect="dark" :content="thisList.attendPlace" placement="bottom">
                    <el-button style="border: none; background: none">上课地点：{{ thisList.attendPlace }}<i
                        class="el-icon-location-information" /></el-button>
                  </el-tooltip>
                </span>
                <!--  -->
              </div>
              共
              <span class="orange_text">{{ thisList.chapterNewVOS.length }}
              </span>
              章
            </div>
          </div>
        </template>
        <!-- 直播 -->
        <chater v-if="thisList.type == 1" :id="thisList.id" class="chater" :if-class="true" :class-id="classesId"
          :cc-config="thisList ? thisList.ccConfig : ''" :purchase="IsPurchase" :is-purchase="IsPurchase"
          :list="thisList.chapterNewVOS" :class-product-line="productLine" :card-code="cardCode"
          :is-freeze="isFreeze" />
        <!-- 录播 -->
        <video-chater v-if="thisList.type == 2" :id="thisList.id" class="video-chat" :if-class="true"
          :is-outsourcing="thisList.isOutsourcing" :class-id="classesId" :is-purchase="IsPurchase"
          :list="thisList.chapterNewVOS" :class-product-line="productLine" :card-code="cardCode"
          :is-freeze="isFreeze" />
        <!-- 音频 -->
        <audio-chat v-if="thisList.type == 3" :id="thisList.id" class="audio" :if-class="true" :class-id="classesId"
          :purchase="IsPurchase" :is-purchase="IsPurchase" :list="thisList.chapterNewVOS" :card-code="cardCode"
          :is-freeze="isFreeze" />
        <!-- 图文 -->
        <img-chat v-if="thisList.type == 4" :id="thisList.id" class="face" :if-class="true" :class-id="classesId"
          :purchase="IsPurchase" :is-purchase="IsPurchase" :list="thisList.chapterNewVOS" :card-code="cardCode"
          :is-freeze="isFreeze" />
        <!-- 面授 -->
        <face-chat v-if="thisList.type == 5" :id="thisList.id" class="face" :if-class="true"
          :attend-place="thisList.attendPlace" :class-id="classesId" :purchase="IsPurchase" :is-purchase="IsPurchase"
          :list="thisList.chapterNewVOS" :card-code="cardCode" :is-freeze="isFreeze" />
      </el-collapse-item>
    </el-collapse>
    <!-- </el-collapse> -->
    <div v-if="!courseList || courseList.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
  </div>
</template>
<script>
import { liveEnums } from '@/api/emun'
import { Know } from '@/api/know'
const know = new Know()
import { getInfo } from '@/api/cookies'
import chaternew from '@/components/Know/course/chaternewtypexbk.vue'
import chater from '@/components/Know/course/chaterxbkbot.vue'
import VideoChater from '@/components/Know/videoChaternewxbk.vue'
import audioChat from '@/components/Know/course/audioChat.vue'
import faceChat from '@/components/Know/course/faceChatxbk.vue'
import imgChat from '@/components/Know/course/imgChat.vue'
export default {
  components: { VideoChater, chater, faceChat, audioChat, imgChat, chaternew },
  props: [
    'classesId',
    'IsPurchase',
    'productLine',
    'cardCode',
    'isFreeze',
    'kind',
    'navIndex'
  ],
  data() {
    return {
      liveEnum: liveEnums,
      userInfo: null,
      list: {
        packages: [],
        paperList: []
      },
      openIndex: 0,
      openChil: 0,
      smallChil: 0,
      yearKc: [],
      currentYear: '',
      courseList: [],
      smallList: [],
      courseName: ''
    }
  },
  created() {
    this.userInfo = getInfo()
    this.getList()
  },
  methods: {
    // 获取试卷包列表
    async getList() {
      await know
        .classesNewTableKsTime(
          this.classesId,
          this.userInfo && this.userInfo.id ? this.userInfo.id : undefined
        )
        .then((res) => {
          if (res.code == 0) {
            this.yearKc = res.data.yearKc
            if (this.yearKc && this.yearKc.length > 0) {
              this.currentYear = res.data.yearKc[0].year
              this.courseName = res.data.yearKc[0].yearKcNames
            }
          }
        })
        .then(() => {

          this.collapseChange(0)
        })
    },
    /* 课程展开  */
    async collapseChange(val) {
      if (!val && val !== 0) return
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      // const item = this.courseName[val];
      const data = []
      if (this.navIndex == 3) {
        await know
          .getPastPrivateClassList(
            this.classesId,
            this.userInfo ? this.userInfo.id : undefined,
            this.currentYear,
            this.IsPurchase ? 1 : 0
          )
          .then((res) => {
            if (res.code == 0) {
              this.courseList = res.data
            }
          })
      } else {
        await know
          .classesCourseTableList(
            this.classesId,
            this.userInfo ? this.userInfo.id : undefined,
            // this.currentYear,
            undefined,
            this.IsPurchase ? 1 : 0
          )
          .then((res) => {
            if (res.code == 0) {
              this.courseList = res.data
            }
          })
      }
      await know.getPrivateClassList(this.classesId).then((res) => {
        if (res.code == 0) {
          res.data.forEach((es) => {
            es.sections.forEach(e => {
              this.$set(e, 'isxbk', true)
              // e.isxbk = true
            })
          })
          this.smallList = res.data
        }
      })
      loading.close()
    },

    changeYear(item) {
      this.courseName = item.yearKcNames
      this.currentYear = item.year
      this.collapseChange(0)
    }
  }
}
</script>
<style lang="less" scoped>
.couserView {
  // width: calc(100% - 60px);
  background: #fff;
  padding-bottom: 20px;
  // margin: 0 auto;

  .couserView_title {
    margin-top: 0px;
    margin-bottom: 0px;

    img {
      width: 236px;
      height: 50px;
      margin: 0 auto;
    }

    // width: 80px;
    // height: 20px;
    font-size: 20px;
    font-family: Source Han Sans CN-Medium,
    Source Han Sans CN;
    font-weight: 500;
    color: #ff5e51;
    line-height: 22px;
  }

  .couserView_title2 {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    height: 90px;
    background-image: url("~@/assets/img/typeclass/titlexiao.png");
    background-repeat: no-repeat;
    background-size: 100% 90px;
    font-size: 22px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: calc(100% - 60px);

    // width: 80px;
    // height: 20px;
    span {
      &:first-child {
        // width: 108px;
        // height: 50px;
        font-size: 36px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        margin-left: 30px;
        line-height: 90px;
      }
    }

    .line {
      display: inline-block;
      margin: 0 20px;
      width: 0px;
      height: 22px;
      opacity: 1;
      border: 1px solid #ffffff;
    }
  }

  .smallListbox {
    width: calc(100% - 60px);
    margin: 0 auto;
    background: #f8f8f8;
    border-radius: 6px 6px 6px 6px;
    padding: 20px 0;
    margin-bottom: 10px;
  }

  .year_wrap {
    min-height: 84px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 32px;
    padding-top: 24px;

    .year_item {
      width: 95px;
      height: 36px;
      border-radius: 8px;
      background-color: #edf0f7;
      color: #565962;
      text-align: center;
      line-height: 36px;
      margin-right: 24px;
      margin-bottom: 20px;
      cursor: pointer;
    }

    .on {
      border: 1px solid #ff5e51;
      background-color: #fff7f6;
      color: #ff5e51;
      font-weight: bold;
    }
  }
}

/deep/.el-collapse {
  border: none !important;

  .redRadio {
    display: none;
  }

  .is-active {
    .redRadio {
      display: inline-block;
    }
  }

  .el-icon-arrow-right {
    transform: rotate(90deg);
    font-size: 16px;
  }

  .is-active.el-icon-arrow-right {
    transform: rotate(270deg);
  }

  .el-collapse-item {
    margin-top: 20px;

    &:first-child {
      margin-top: 10px;

      .el-collapse-item__header {
        margin-top: 0;
      }
    }
  }

  .el-collapse-item__header {
    height: 58px;
    padding: 19px 32px 19px 0px;
    border: none !important;
    position: relative;

    .title {
      display: flex;
      height: 20px;
      align-items: center;
      line-height: 20px;

      .title-name {
        font: 18px Verdana, Arial, "\5FAE\8F6F\96C5\9ED1";
        /* font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei; */
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        margin-right: 20px;

        .iconfont {
          color: #333333;
          font-size: 12px;
          margin-right: 9px;
        }

        .tag {
          width: 30px;
          height: 17px;
          margin-left: 8px;
          border-radius: 0 4px 0 4px;
          color: #ffffff;
          font-size: 11px;
          font-weight: 400;
          text-align: center;
          line-height: 17px;
        }

        .must {
          background: linear-gradient(180deg, #458cff 30%, #91c4ff 100%);
        }

        .notRequired {
          background: linear-gradient(180deg, #ff8a00 48%, #facb23 100%);
        }

        .practice {
          background: linear-gradient(180deg, #9832ff 48%, #dcbaff 100%);
        }
      }

      .shu {
        width: 4px;
        height: 20px;
        background: #fff;
        border-radius: 0px 2px 2px 0px;
        opacity: 1;
        margin-right: 29px;
      }
    }

    .fenge {
      // width: 816px;
      height: 1px;
      background: #eeeeee;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: absolute;
      bottom: 0px;
      left: 32px;
      right: 32px;
    }
  }

  .is-active {
    .shu {
      background: #ff5e51 !important;
    }
  }

  .el-collapse-item__content {
    border: none !important;
    padding-bottom: 0px;
  }

  .el-collapse-item__wrap {
    border: none !important;
  }
}

/* 按章列表 */
/deep/ .docList {
  padding: 0px 32px;

  .el-collapse {
    border: none !important;
  }

  .el-collapse-item__header {
    height: 52px;
    background: #f6f6f6;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    padding: 0px 12px 0 0;
    margin-top: 10px;
  }

  .docList-type {
    height: 20px;
    // background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #ff7b1c;
    padding: 0px 6px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff7b1c;
    line-height: 18px;
    flex-shrink: 0;
    margin-right: 17px;
  }

  .docList-title {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .couserView_title_line {
      display: inline-block;
      margin-right: 14px;
      // vertical-align: middle;
      width: 6px;
      height: 18px;
      background: #ff5e51;
      border-radius: 0px 3px 3px 0px;
      opacity: 1;
    }

    .flex-s,
    .title-text {
      max-width: 650px;
      margin-right: 10px;
    }

    .title-text,
    .title {
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ff5e51;
      // line-height: 16px;
    }

    .tips {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #999999;
      // line-height: 12px;
      margin-right: 27px;

      // margin-top: 14px;
      .orange_text {
        text-align: end;
        color: #ff7b1c;
      }

      .grey_text {
        margin-top: 0;
        display: block;
        color: grey;
      }
    }

    .iconfont {
      font-size: 16px;
      width: 25px;
      height: 25px;
      transform: rotate(-90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }

  .open {
    .iconfont {
      transform: rotate(90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }

  .uni-collapse-cell__title-text {
    border: none !important;
  }

  .uni-collapse-cell--open {
    .uni-collapse-cell__title-text {
      border: none !important;
    }
  }
}

/* 录播章节 音频 */
/deep/ .video-chat,
/deep/.audio {
  padding: 0px;
  border-top: 1px solid #eee;

  .el-collapse-item__header {
    padding: 0px 20px !important;
    height: 42px !important;
    line-height: 42px;
    border-left: none !important;
    background: #fff !important;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    // background: #f8f8f8 !important;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
  }

  .el-collapse-item__content {
    .chat-item {
      margin: 0px 35px;
      background: none;
    }
  }
}

/deep/ .chater {
  // border-top: 1px solid #eee;
  // border-bottom: 1px solid #eee;
  .chat-item {
    padding: 0px 28px;
  }
}

/* 试卷bao列表 */
.paperList {
  width: 100%;
  padding: 0px 32px 20px;

  .item-box {
    .item {
      width: 100%;
      display: flex;
      height: 264px;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      padding: 32px;
      margin-bottom: 24px;
      position: relative;

      .item-image {
        width: 200px;
        height: 200px;
        flex-shrink: 0;
        background: #f7f8f9;
        margin-right: 24px;
      }

      .item-content {
        width: 100%;

        .item-title {
          height: 88px;
          font-size: 28px;
          font-family: PingFang SC-Medium;
          font-weight: 500;
          color: #373a42;
          line-height: 44px;
        }

        .item-num {
          padding: 0px 12px;
          display: inline-block;
          height: 34px;
          background: #fff6ef;
          border-radius: 8px 8px 8px 8px;
          font-size: 22px;
          font-family: PingFang SC-Regular;
          font-weight: 400;
          color: #ff852d;
          line-height: 34px;
          margin-top: 8px;
        }

        .item-tips {
          margin-top: 34px;
          font-size: 24px;
          font-family: PingFang SC-Medium;
          line-height: 36px;
          font-weight: 500;
          color: #ff5d51;
          flex-shrink: 0;
        }
      }
    }
  }
}

/* 章节列表 */
.chat-list {
  padding: 0px;

  .chat-item {
    width: 100%;
    padding: 32px 0px;

    .item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 300px);

      .title {
        font-size: 28px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        color: #373a42;
        line-height: 28px;
      }

      .time {
        font-size: 24px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #9fa4ad;
        line-height: 24px;
        margin-top: 16px;
      }

      /* 直播 */
      .liveIng {
        font-size: 24px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #ff5d51;
        line-height: 24px;

        .live {
          color: #ff5d51;
          font-size: 24px;
          margin-right: 8px;
        }
      }

      /* 回放 */
      .video {
        color: #6c7079;
        font-size: 40px;
      }
    }

    /* 未开始  回放生成中 */
    .noLive {
      color: #9fa4ad;

      .title,
      .time,
      .video {
        color: #9fa4ad !important;
      }
    }

    .but-list {
      flex-shrink: 0;
      display: flex;
      width: 240px;
      justify-content: flex-end;
      align-items: center;

      .keep-learn {
        margin-right: 12px;
        flex-shrink: 0;
        width: 72px;
        height: 24px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #ff5e51;
        padding: 0px;
        text-align: center;
        line-height: 24px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    /* .but-list {
      display: flex;
      margin-top: 48px;
      justify-content: flex-end;
      flex-shrink: 0;
      .item-but {
        margin-left: 24px;
        padding: 0px 24px;
        height: 48px;
        border-radius: 8px 8px 8px 8px;

        border: 2px solid #ff5d51;
        font-size: 24px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #ff5d51;
        line-height: 48px;
        text-align: center;
        &:last-child {
          margin-right: 0px;
        }
      }
    } */
  }
}

// /deep/ .el-collapse-item__arrow {
//   display: block;
//   // background-color: #333;
//   height: 32px;
//   width: 100px;
//   background-image: url("~@/assets/img/vipxq/cha.png");
//   transform: rotate(0deg) !important;
//   background-size: 100px 32px;
//   background-repeat: no-repeat;
// }</style>
