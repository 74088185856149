<template>
    <div class="textBookWrap">
        <div class="textBook"
            v-for="item,index in textBookList"
            :key="index"
        >
            <div class="textBookItem">
                <div>
                    <el-image class="imageItem"></el-image>
                    <div class="tabs">可试看</div>
                </div>
                <div>
                    <div class="titleItem">2021年公证与法律制度与公证与法律法制度</div>
                    <div class="author">作者：唐青枫</div>
                    <div class="sasuke">简介：《中医基础理论》第4版教材是国家卫生健康委员会“十三五”规划教材，全国中医药高职高专教育教材，主要供全国中医药高职高专院校中医学、针灸推拿、中医骨伤、护理等专业针灸推拿、中医骨伤、护理等护理等专业...</div>
                </div>
                <div class="btn">
                    <el-button size="mini" type="" @click="goTextbookDetails">教材详情</el-button>
                    <el-button size="mini" type="danger">免费试看</el-button>
                </div>
            </div>
            <el-divider></el-divider>
        </div>
        <div class="nothing" v-if="textBookList.length == 0">
            <div class="nothing-text">暂无内容哦~</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                textBookList: [
                    {},
                    {}
                ]
            }
        },
        methods: {
            goTextbookDetails() {
                this.$router.push({
                    path: '/typeclassxq/textbookDetails',
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .textBook{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .textBook /deep/ .el-divider{
        margin: 0;
        width: 816px;
        background-color: #EEEEEE;
    }
    .textBookWrap{
        background-color: #fff;
    }
    .textBookItem{
        display: flex;
        width: 100%;
        height: 192px;
        padding: 24px 32px 0 32px;
        position: relative;
    }
    .imageItem{
        width: 108px;
        height: 144px;
        border-radius: 8px 8px 8px 8px;
        margin-right: 12px;
    }
    .tabs{
        width: 52px;
        height: 20px;
        background-color: rgba(255, 94, 81, 1);
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        position: absolute;
        top: 24px;
        left: 32px;
        text-align: center;
        line-height: 20px;
        border-radius: 8px 0px 8px 0px;
    }
    .titleItem{
        color: rgba(51, 51, 51, 1);
    }
    .author{
        margin-top: 16px;
        color: rgba(102, 102, 102, 1);
    }
    .sasuke{
        margin-top: 24px;
        width: 420px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
    }
    .btn{
        display: flex;
        align-items: center;
        margin-left: 100px;
    }
</style>