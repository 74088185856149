<template>
    <div class="educateWrap">
        <div class="serve">
            <div class="title">
                <div class="color-FF5E51"></div>
                <span>专属服务</span>
            </div>
            <div class="main">
                <div class="mainItam">
                    <img src="@/assets/img/Home/notice.png">
                    <span>在线答疑</span>
                </div>
            </div>
        </div>
        <div class="details">
            <div class="title">
                <div class="color-FF5E51"></div>
                <span>教服详情</span>
            </div>
            <div class="image" v-html="ImgSizeLimit(detail.jfServer)"></div>
        </div>
        <!-- <div class="classMeeting">
            <div class="title">
                <div class="color-FF5E51"></div>
                <span>班会</span>
            </div>
            <div class="main">
                <div 
                    class="mainItem" 
                    v-for="item,index in classMeetingList"
                    :key="index"
                >
                    <div class="mainItemTitle" v-if="item.index == 1">
                        <img class="img" src="@/assets/img/kecheng/Group1339.png"/>
                        <span >正在直播中</span>
                    </div>
                    <div class="mainItemTitle" v-if="item.index == 2">
                        <span >距直播开始：</span>
                        <div class="block">02</div>
                        <span>：</span>
                        <div class="block">24</div>
                        <span>：</span>
                        <div class="block">32</div>
                    </div>
                    <div class="mainItemTitle" v-if="item.index == 3">
                        <span >距直播开始：</span>
                        <div class="block">02</div>
                        <span>：</span>
                        <div class="block">24</div>
                        <span>：</span>
                        <div class="block">32</div>
                        <div class="right">
                            <span>
                                预约成功
                            </span>
                        </div>
                    </div>
                    <div class="mainItemTitle" v-if="item.index == 4">
                        <span >直播已结束</span>
                    </div>

                    <div class="contentItem">
                        <el-image class="image"></el-image>
                        <div class="contentMain">
                            <div class="contentText">
                                <div class="contentTitle">{{item.value}}</div>
                                <div class="contentTime">{{`直播时间：${item.time}`}}</div>
                            </div>
                            <div class="contentBtn">
                                <span>{{ `${item.people}人预约` }}</span>
                                <el-button class="btn" size="mini" type="danger" v-if="item.index == 1">立即观看</el-button>
                                <el-button class="btn" size="mini" type="danger" v-else-if="item.index == 2">立即预约</el-button>
                                <el-button class="btn" size="mini" type="info" v-else-if="item.index == 3" disabled>立即观看</el-button>
                                <el-button class="btn" size="mini" v-else>观看回放</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        props: ['detail'],
        data() {
            return {
                classMeetingList: [
                    {index: 1, value: '2021年元宇宙概念班会-第二期', time: '2021-12-10 12:30', people: 3000},
                    {index: 2, value: '2021年元宇宙概念班会-第二期', time: '2021-12-10 12:30', people: 3000},
                    {index: 3, value: '2021年元宇宙概念班会', time: '2021-12-10 12:30', people: 3000},
                    {index: 4, value: '2021年元宇宙概念班会', time: '2021-12-10 12:30', people: 3000}
                ]
            }
        }
    }
</script>

<style lang="less" scoped>
    .educateWrap{
        background-color: #fff;
        padding: 32px;
        width: 100%;
        display: flex;
        flex-direction: column;
        .serve{
            margin-bottom: 20px;
            
            .main{
                display: flex;
                justify-content: space-between;
                .mainItam{
                    /* text-align: center;
                    line-height: 80px; */
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 250px;
                    height: 80px;
                    background-color: #F5F7F9;
                    font-size: 18px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    border-radius: 8px;
                }
            }
        }
        .title{
            display: flex;
            align-items: center;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            margin-bottom: 20px;
            .color-FF5E51{
                background-color: #FF5E51;
                width: 3px;
                height: 14px;
                margin-right: 8px;
            }
        }
        .details{
            margin-bottom: 32px;
            .image{
                /* width: 816px;
                height: 816px; */
                border-radius: 8px;
            }
        }
        .classMeeting{
            .main{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .mainItem{
                    width: 396px;
                    height: 156px;
                    background-color: #EEEEEE;
                    border-radius: 8px;
                    margin-bottom: 24px;
                    padding: 18px 16px 0 16px;
                    .mainItemTitle{
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        color: #6C7079;
                        font-size: 14px;
                        font-family: PingFang SC-Regular;
                        .img{
                            margin-right: 4px;
                        }
                        .block{
                            width: 16px;
                            height: 16px;
                            background-color: #FF5E51;
                            color: #FFFFFF;
                            font-size: 10px;
                            line-height: 16px;
                            text-align: center;
                            border-radius: 2px;
                        }
                        .right{
                            margin-left: 156px;
                            font-size: 12px;
                            color: #FF5E51;
                        }
                    }
                    .contentItem{
                        display: flex;
                        .image{
                            width: 138px;
                            height: 92px;
                            border-radius: 8px;
                            margin-right: 12px;
                        }
                        .contentMain{
                            display: flex;
                            flex-direction: column;
                            .contentText{
                                margin-bottom: 22px;
                                .contentTitle{
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #373A42;
                                    margin-bottom: 12px;
                                }
                                .contentTime{
                                    font-size: 12px;
                                    color: #6C7079;
                                }
                            }
                            .contentBtn{
                                font-size: 12px;
                                color: #9FA4AD;
                                display: flex;
                                align-items: center;
                                .btn{
                                    margin-left: 69px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
</style>