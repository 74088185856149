var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warpConss chat-list"},[_vm._l((_vm.CourseList),function(item,index){return _c('div',{key:index,staticClass:"chat-item"},[_c('div',{staticClass:"item-title",class:item.isLive == 0 || item.isLive == 2 ? 'noLive' : '',on:{"click":function($event){$event.stopPropagation();return _vm.gkcb(item)}}},[_c('div',{staticClass:"tt-box",on:{"click":function($event){$event.stopPropagation();return _vm.gkcb(item)}}},[_c('div',{staticClass:"flex-c ellipsis",class:item.isTrial == 1 && item.isLive == 3 && !_vm.IsPurchase
            ? 'course_title'
            : ''},[_c('div',{staticClass:"title ellipsis",class:item.isLive == 1 ? 'lilve-title' : ''},[(item.isLive == 0 || item.isLive == 2)?_c('i',{staticClass:"iconfont icon-suotou"}):_c('i',{staticClass:"iconfont icon-a-Union21"}),_vm._v(" "+_vm._s(item.title)+" ")]),(item.isLive == 1)?_c('div',{staticClass:"liveIng",on:{"click":function($event){$event.stopPropagation();return _vm.gkZb(item, 1)}}},[_c('i',{staticClass:"live iconfont icon-a-Group1339"}),_vm._v(" 正在直播中 ")]):_vm._e(),_c('span',{staticClass:"time ellipsis"},[(!item.sectionName)?_c('span',[_vm._v(" "+_vm._s(item.ksTime ? item.ksTime : 0)+" 课时 ")]):_c('span',[_vm._v(_vm._s(_vm._f("format")(item.startTime,"yyyy.MM.dd HH:mm")))])])])])]),_c('div',{staticClass:"but-list"},[_c('div',{class:item.isLive == 0 || item.isLive == 2 ? 'noLives' : ''},[(_vm.ifClass ? _vm.dealDocType(item, 2) : item.isHasContantJY == 'true'
          )?_c('div',{staticClass:"keep-learn",on:{"click":function($event){$event.stopPropagation();return _vm.goData(item, 4)}}},[_vm._v(" 随堂讲义 ")]):_vm._e(),(_vm.ifClass
            ? item.contactList.length > 0
            : item.isHasContant == 'true'
          )?_c('div',{staticClass:"keep-learn",on:{"click":function($event){$event.stopPropagation();return _vm.cgeckTask(item)}}},[_vm._v(" 随堂测试 ")]):_vm._e(),(_vm.ifClass ? _vm.dealDocType(item, 1) : item.isHasDoc == 'true')?_c('div',{staticClass:"keep-learn",on:{"click":function($event){$event.stopPropagation();return _vm.goData(item, 2)}}},[_vm._v(" 随堂资料 ")]):_vm._e()])])])}),(_vm.dialogVisible)?_c('div',{staticClass:"warpCpons",on:{"touchmove":function($event){$event.stopPropagation();$event.preventDefault();return _vm.stop()}}},[_c('div',[_c('img',{staticClass:"imga",attrs:{"src":require("@/assets/img/kecheng/No1.png")}}),_c('img',{staticClass:"imgb",attrs:{"src":require("@/assets/img/kecheng/close.png")},on:{"click":_vm.tChuang}}),_c('span',[_vm._v("您暂未购买该课程哦~")])])]):_vm._e(),(_vm.showLookDetail)?_c('div',{staticClass:"lookBg"},[_c('div',{staticClass:"ifLook"},[_c('div',{staticClass:"prompt"},[_vm._v("提示")]),_c('div',{staticClass:"contents"},[_vm._v(" 您当前正在"),_c('span',[_vm._v(_vm._s(_vm.lookDeatil ? _vm.lookDeatil.terminal : ""))]),_vm._v("观看"),_c('span',[_vm._v(_vm._s(_vm.lookDeatil ? _vm.lookDeatil.chapterName : ""))]),_vm._v(",请关闭后再行观看其他章节。 ")]),_c('div',{staticClass:"button",on:{"click":_vm.closeLook}},[_vm._v("关闭")])])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }