<template>
  <!-- 题库 -->
  <div class="questionBank">
    <div class="room" v-if="list && list.length > 0">
      <div class="item-box" v-for="(item, index) in list" :key="index" @click="tabId = item.id">
        <div class="item">
          <img class="item-image" src="@/assets/img/study/exmRoom.png" alt="" />
          <div class="item-content">
            <div class="item-title twoEllipsis">
              <span class="title-type">{{ mTypeEnum[item.type] }}</span>
              {{ item.name }}
            </div>
            <div class="item-num">
              <span>{{ item.startTime }}</span> -
              <span>{{ item.endTime }}</span>
            </div>
            <div class="item-num">{{ item.kindName }}{{ item.majorName }}</div>
            <div class="item-tips">{{ item.roomPaperSize }}套试卷</div>
          </div>

          <div v-if="(((!item.tenantPrice || item.tenantPrice == 0) && item.type == 1) || item.showStatus == 1) &&
            (item.roomStatus == 2 || item.roomStatus == 3)
            " class="keep-learn keep-check" @click="goRoom(item)">
            进入考场
          </div>
          <div v-else-if="item.roomStatus == 1 || item.type == 2" @click="expired(item)" class="keep-learn keep-ok">
            进入考场
          </div>
        </div>
      </div>
    </div>
    <div class="nothing" v-if="!list || list.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { roomType } from "@/api/emun";
import { Know } from "@/api/know";
let know = new Know();
import { getInfo } from "@/api/cookies";
import chater from "@/components/Know/course/chater.vue";
export default {
  components: { chater },
  data() {
    return {
      mTypeEnum: roomType,
      userInfo: {},
      list: [],
    };
  },
  props: ["classesId", "IsPurchase"],
  created() {
    this.userInfo = getInfo();
    this.getList();
  },
  methods: {
    goRoom(item) {
      this.$router.push(`/quesTion/examRoom/examRoomDetail?roomId=${item.id}`);
    },
    // 获取列表
    getList() {
      /* 专属资料 */
      know.selectClassesRoomList(this.classesId).then((res) => {
        if (res.code == 0) {
          this.list = res.data;
        } else {
          this.list = [];
        }
      });
    },
    expired(item) {
      if (item.roomStatus == 1) {
        this.$message({
          type: "warning",
          message: "考场未开始",
        });
      } else if (item.roomStatus == 3) {
        this.$message({
          type: "warning",
          message: "考场已结束",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.questionBank {
  background: #ffffff;
}

/* 虚拟考场 */
.room {
  width: 100%;

  .item-box {
    .item {
      width: 100%;
      display: flex;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      padding: 32px;
      margin-bottom: 24px;
      align-items: center;
      position: relative;

      .item-image {
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        background: #f7f8f9;
        margin-right: 12px;
      }

      .item-content {
        width: 100%;

        .item-title {
          // height: 48px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 24px;

          .title-type {
            height: 16px;
            background: #ffeee2;
            border-radius: 2px;
            font-size: 11px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            color: #ff7b1c;
            line-height: 11px;
            line-height: 16px;
            display: inline-block;
            margin-right: 8px;
            padding: 0px 6px;
          }
        }

        .item-num {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          margin-top: 8px;
        }

        .item-tips {
          margin-top: 18px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 12px;
        }
      }
    }
  }
}

/* 按钮 */
.keep-learn {
  height: 30px;
  line-height: 28px;
  padding: 0px 16px;
  position: absolute;
  right: 32px;
  flex-shrink: 0;
}
</style>
